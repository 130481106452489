export const environment = {
  production: false,
  occBaseUrl: 'https://api.c9guxrh1t0-osbornedi1-d1-public.model-t.cc.commerce.ondemand.com',
  paypal: {
    id: 'AeJSUlhxPsOVV5ISvFRwQGCLJ9o837meTo5Fee_wjZMRR9jmRMXqeJYrHt0uJSdY4s20kOe3Bl_rwa3m',
    src: 'https://www.paypal.com/sdk/js',
    installments: {
      active: true,
      threshold: 60.0,
    },
  },
  gtmId: 'GTM-M5MLVMPH',
  reCaptcha: {
    enabled: true,
    scriptSrc: 'https://www.google.com/recaptcha/api.js',
    onloadCallbackName: 'recaptchaCallback',
    v2: {
      siteKey: '6LfDaf8eAAAAAAFtczB04T0abSBTzDV3Q-0fybJE',
    },
    v3: {
      siteKey: '',
    },
  },
  cookiepro: {
    enabled: true,
    src: 'https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js',
    id: '019072b1-85ab-7fcf-aef6-9de55345f264-test',
    charset: 'UTF-8',
  },
  bugherd: {
    enabled: true,
    src: 'https://www.bugherd.com/sidebarv2.js',
    apikey: '3r1kzbvqxngjl67nxz7xsg',
  },
  emarsysConfig: {
    merchantId: '16715D27DACAC2F7',
    testMode: true,
  },
};
