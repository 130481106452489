import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { MediaComponent } from '@spartacus/storefront';
import { CjCmsBannerComponentMedia } from './media.model';
import { CjMediaService } from './media.service';

@Component({
  selector: 'cj-media',
  templateUrl: './media.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CjMediaComponent extends MediaComponent implements AfterViewInit, OnDestroy {
  @ViewChild('videoPlayer') videoPlayer!: ElementRef<HTMLVideoElement>;
  @Input() muted: boolean = true;

  constructor(
    protected override mediaService: CjMediaService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    super(mediaService);
  }

  ngAfterViewInit(): void {
    if (this.videoPlayer?.nativeElement && this.isVideo(this.container)) {
      this.playPauseVideo();
    }
  }

  public isVideo(container: unknown): boolean {
    const media = container as CjCmsBannerComponentMedia;

    return media?.mime?.includes('video') || !!media?.video || media?.isExternalUrlVideo === true;
  }

  private playPauseVideo() {
    // Ensure the code runs only in the browser
    if (isPlatformBrowser(this.platformId) && this.videoPlayer) {
      // We can only control playback without interaction if video is mute
      this.videoPlayer.nativeElement.muted = this.muted;
      // Play is a promise so we need to check we have it
      this.videoPlayer.nativeElement.play()?.then(() => {
        const observer = new IntersectionObserver(
          (entries) => {
            this.videoPlayer.nativeElement &&
              entries.forEach((entry) => {
                if (entry.intersectionRatio !== 1 && !this.videoPlayer.nativeElement.paused) {
                  this.videoPlayer.nativeElement?.pause();
                } else if (this.videoPlayer.nativeElement.paused) {
                  this.videoPlayer.nativeElement?.play();
                }
              });
          },
          { rootMargin: '150px 150px 150px 150px' },
        );
        observer.observe(this.videoPlayer.nativeElement);
      });
    }
  }

  ngOnDestroy() {
    // Makes sure the video is destroyed if its removed from the DOM
    // Else might keep playing in the background even if its not visible
    this.videoPlayer.nativeElement = null as unknown as HTMLVideoElement;
  }
}
